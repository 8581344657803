@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .custom-scrollbar::-webkit-scrollbar {
      width: 8px; /* Width of the scrollbar */
    }
    
    .custom-scrollbar::-webkit-scrollbar-track {
      background: #f1f1f1; /* Color of the track */
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background: #888; /* Color of the scrollbar thumb */
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #555; /* Color of the scrollbar thumb on hover */
    }
    
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

.toastBody {
  font-family: 'Roboto', sans-serif;
  color: #000;
}

.toastProgress {
  background: #000 !important;
}

.toastBodyDark {
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

.toastProgressDark {
  background: #fff !important;
}

.textarea-container {
  position: relative;
}

.textarea {
  resize: none;
  overflow-y: hidden;
}

.textarea::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.fancyOverlay,
.fancyGlass {
  --primary-light: theme('colors.slate.400');
  --primary-main: theme('colors.slate.500');
  --primary-darker: theme('colors.slate.900');
  --secondary-light: theme('colors.stone.400');
  --secondary-main: theme('colors.stone.500');
  --secondary-darker: theme('colors.stone.900');
  --glass-color: 120, 113, 108;
}

/**
  * Overlay gradients & animation - used as page background.
  */
@property --fancy-x {
  syntax: '<percentage>';
  inherits: true;
  initial-value: 0%;
}
@property --fancy-y {
  syntax: '<percentage>';
  inherits: true;
  initial-value: 0%;
}

@keyframes roundabout {
  0% {
    --fancy-x: 60%;
    --fancy-y: 20%;

    opacity: 0;
  }

  5% {
    --fancy-x: 80%;
    --fancy-y: 10%;
  }

  20% {
    --fancy-x: 95%;
    --fancy-y: 5%;

    opacity: var(--maximum-opacity);
  }

  100% {
    --fancy-x: 100%;
    --fancy-y: 0%;

    opacity: var(--maximum-opacity);
  }
}

.fancyOverlay::after {
  --maximum-opacity: 0.1;

  content: '';
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    54deg,
    var(--primary-light) var(--fancy-y) var(--fancy-y),
    var(--secondary-light) var(--fancy-x) var(--fancy-x)
  );
  animation: roundabout 10s ease-in-out both;
}

/**
  * Glass effect with a gradient background and blur - used for highlighting pricing cards.
  */
.fancyGlass,
.fancyGlassContrast {
  background: radial-gradient(
      63.94% 63.94% at 50% 0%,
      rgba(var(--glass-color), 0.12) 0%,
      rgba(var(--glass-color), 0) 100%
    ),
    rgba(var(--glass-color), 0.01);
  backdrop-filter: blur(6px);
  position: relative;
  overflow: hidden;
}

.fancyGlassContrast:after {
  content: '';
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: var(--primary-darker);
  opacity: 0.1;
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: -1;
}

.fancyGlassContrast:before,
.fancyGlass:before {
  content: '';
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: linear-gradient(
      rgba(var(--glass-color), 0.12) 0%,
      rgba(var(--glass-color), 0) 74.04%
    ),
    linear-gradient(
      0deg,
      rgba(var(--glass-color), 0.04),
      rgba(var(--glass-color), 0.04)
    );
  position: absolute;
  top: -1px;
  left: -1px;
  mask: url("data:image/svg+xml,%3Csvg width='402' height='202' viewBox='0 0 402 202' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='401' height='201' rx='9.5' /%3E%3C/svg%3E%0A");
  pointer-events: none;
}